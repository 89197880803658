import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import axios from "axios";
import moment from "moment";

import { ts_logo } from "../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import { Table } from "antd";
import DeleteEntityDocumentModal from "./DeleteEntityDocumentModal";
import EditEntityDocumentModal from "./EditEntityDocumentModal";
import { checkPolicyPage, getApiUrl } from "../../../utils/AuthUtils";
import { getStatusBadgeClass } from "../../../utils/Misc";
import _ from "lodash";

const EntityDocuments = forwardRef((props, ref) => {
  const [entityDocumentsData, setEntityDocumentsData] = useState([]);
  const [entityDocumentData, setEntityDocumentData] = useState(null);
  const documentTypesOptions = JSON.parse(
    localStorage.getItem("document_types")
  );
  const policyPage = checkPolicyPage();
  const currentUserRole = localStorage.getItem("role");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("id");
  const [sortingOrder, setSortingOrder] = useState("desc");

  useImperativeHandle(ref, () => ({
    updateDocumentsList() {
      fetchData();
    },
  }));

  const fetchData = async () => {
    try {
      const response = await axios.get(
        getApiUrl() + `/api/entities/${props.entity.id}/documents`,
        {
          params: {
            filter: props.filterText, // add filter if needed
            limit: !policyPage ? pageSize : 1000,
            page: !policyPage ? currentPage : 1,
            order: sortingOrder,
            orderField: sortingColumn,
          },
        }
      );
      let entityDocumentData = response.data.data.data;
      setTotalRecords(response.data.data.total);
      if (currentUserRole != "tenant-admin") {
        entityDocumentData = entityDocumentData.filter((item) => {
          return item.status === "active";
        });
      }
      entityDocumentData = entityDocumentData.map((item) => {
        return {
          ...item,
          image: ts_logo,
          status: item.status === "active" ? "Active" : "Inactive",
        };
      });
      setEntityDocumentsData(entityDocumentData);
    } catch (error) {
      console.error("Error fetching entities data", error);
    }
  };
  useEffect(() => {
    if (props.entity && props.entity.id) {
      fetchData();
    }
  }, [
    currentPage,
    pageSize,
    sortingColumn,
    sortingOrder,
    props.filterText,
    props.entity.id,
  ]);

  const handleUpdateList = () => {
    fetchData();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (typeof sorter.order === "undefined") {
      setSortingOrder("desc");
      setSortingColumn("id");
    } else {
      setSortingColumn(sorter.field);
      setSortingOrder(sorter.order === "ascend" ? "asc" : "desc");
    }
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: "Document Category",
      dataIndex: "document_category",
      render: (text, record) => (
        <div className="d-flex">
          <div>
            <h2 className="table-avatar d-flex align-items-center">
              <div to="#" className="profile-split">
                {record.document_category}
              </div>
            </h2>
          </div>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "document_title",
      render: (text, record) => (
        <div className="d-flex">
          <div>
            <Link
              to={record.file_info.file_path}
              target="_blank"
              style={{ color: "#0281df", textDecoration: "underline" }}
            >
              {record.document_title}
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Size",
      dataIndex: "document_size",
      render: (text, record) => (
        <div className="d-flex">
          <div>{record.file_info.file_size}</div>
        </div>
      ),
    },
    {
      title: "Last Updated",
      dataIndex: "document_last_updated",
      render: (text, record) => {
        return moment(record.modified_at).format("D MMMM YYYY, hh:mm A");
      },
    },
  ];

  if (currentUserRole === "tenant-admin") {
    columns.push({
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        const badgeClass = getStatusBadgeClass(_.toLower(text));
        return <span className={badgeClass}>{_.capitalize(text)}</span>; // Return styled status badge
      },
    });
    columns.push({
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_entity_document"
              onClick={(e) => {
                e.preventDefault();
                setEntityDocumentData(record);
                // setCompanyId(record.id);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_company_document"
              onClick={(e) => {
                e.preventDefault();
                setEntityDocumentData(record);
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    });
  }
  return (
    <>
      <div className="table-responsive">
        <Table
          className="table-striped"
          columns={columns}
          dataSource={entityDocumentsData}
          rowKey={(record) => record.id}
          pagination={
            policyPage
              ? false
              : {
                  current: currentPage,
                  pageSize: pageSize,
                  total: totalRecords,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }
          }
          onChange={handleTableChange}
        />
      </div>
      <EditEntityDocumentModal
        entity={props.entity}
        entityDocument={entityDocumentData}
        handleUpdateList={handleUpdateList}
      />
      <DeleteEntityDocumentModal
        entity={props.entity}
        entityDocument={entityDocumentData}
        handleUpdateList={handleUpdateList}
      />
    </>
  );
});

export default EntityDocuments;
