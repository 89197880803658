import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import Select from "react-select";
import PreLoader from "../../PreLoader";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import {
  getLocalStorageJSONData,
  setLocalStorageJSONData,
} from "../../../utils/Misc";
// import Alert from "../../Alert";

const validationSchema = Yup.object().shape({
  document_category: Yup.object().required("Please select document category"),
  document_title: Yup.string().trim().required("Document title is required"),
  visible_to: Yup.object().required("Please select visibility option"),
  status: Yup.object().required("Please select status"),
});

const EditEntityDocumentModal = (props) => {
  const headers = getAuthHeaders();
  const [logoFile, setDocumentFile] = useState(null);
  const [isMandatory, setIsMandatory] = useState(0);
  const [showErrors, setShowErrors] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState("");
  const [documentTypeOptions, setDocumentTypeOptions] = useState(
    getLocalStorageJSONData("document_types")
  );

  const [statusOptions, setStatusOptions] = useState(
    getLocalStorageJSONData("status")
  );
  const [documentCategoriesOptions, setDocumentCategoriesOptions] = useState(
    []
  );
  const [visibelToOptions, setVisibleToOptions] = useState([]);

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    entity_document_file: null,
    document_title: "",
    description: "",
    visible_to: null,
    is_mandatory: 0,
    document_category: null,
    comments: "",
    status: null,
  });
  const {
    reset,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const fetchEntityData = async () => {
      try {
        const response = await axios.get(
          `${getApiUrl()}/api/masters/entity-document-masters`
        );
        if (response) {
          setLocalStorageJSONData("visible_to", response.data.data.visible_to);
          setLocalStorageJSONData(
            "document_categories",
            response.data.data.document_categories
          );
          setLocalStorageJSONData("status", response.data.data.status);
        }
        setDocumentCategoriesOptions(
          getLocalStorageJSONData("document_categories").map(
            (document_category) => {
              return {
                value: document_category.id,
                label: document_category.name,
              };
            }
          )
        );
        setStatusOptions(getLocalStorageJSONData("status"));
        setVisibleToOptions(getLocalStorageJSONData("visible_to"));
        resetFileHandler();
      } catch (err) {
        // setError(err.message);
        // setLoading(false);
      }
    };

    fetchEntityData();
  }, []);

  const handleFileUpload = (e) => {
    setDocumentFile(e.target.files[0]);
    setValue("entity_document_file", e.target.files[0], { shouldDirty: true });
  };

  const resetFileHandler = (e) => {
    document.getElementById("entity_document_file").value = null;
    setDocumentFile(null);
    setValue("entity_document_file", null, { shouldDirty: true });
  };

  const onSubmitEntityDocument = async (data) => {
    const finalFormData = {
      id: props.entityDocument.id,
      document_title: data.document_title.trim(),
      visible_to: data.visible_to.value,
      is_mandatory: isMandatory,
      description: data.description.trim(),
      comments: data.comments.trim(),
      document_category_id: data.document_category.value,
      status: data.status.value,
    };

    let newFormData = finalFormData;
    const url = `${getApiUrl()}/api/entities/${
      props.entity.id
    }/documents/update`;
    let config = {
      headers,
    };

    if (data.entity_document_file) {
      config = {
        headers: {
          ...headers,
          "content-type": "multipart/form-data",
        },
      };

      newFormData = new FormData();
      newFormData.append("entity_document_file", data.entity_document_file);
      for (let key in finalFormData) {
        newFormData.append(key, finalFormData[key]);
      }
    }

    try {
      const response = await axios.put(url, newFormData, config);

      // Reset form after successful submission
      const updated_row_count = response.data.data[0].updated_row_count;
      if (updated_row_count == 1) {
        document.getElementById("close-edit-modal-entity-document").click();
        document.getElementById("success_update_btn_document").click();
        props.handleUpdateList();
        resetFileHandler();
        setTimeout(() => {
          document.getElementById("close-modal-update-success").click();
        }, 3000);
      }
    } catch (error) {
      // setErrorAlert(true);
      if (error.response && error.response.status > 300) {
        const errorData = error.response.data.message;
        const errorField = error.response.data.field;
        setShowErrors(false);
        if (errorField) {
          setError(
            errorField,
            {
              type: "focus",
              message: errorData,
            },
            { shouldFocus: true }
          );
        }
        setErrorsMessage(errorData);
        setShowErrors(true);
      } else {
        // Handle other types of errors (e.g., network errors)
        console.error("Error:", error);
        // You can show a generic error message to the user if needed
      }
    }
    // FieldsetTwo();
  };
  useEffect(() => {
    const fetchEntityData = async () => {
      try {
        if (props.entityDocument) {
          const response = await axios.get(
            `${getApiUrl()}/api/entities/${props.entity.id}/documents/${
              props.entityDocument.id
            }`
          );
          const editFormData = {
            ...formData,
            ...response.data.data,
            document_category:
              documentCategoriesOptions &&
              documentCategoriesOptions.filter(
                (document_category) =>
                  document_category.value ===
                  response.data.data.document_category_id
              )[0],
            visible_to:
              visibelToOptions &&
              visibelToOptions.filter(
                (visible_to) =>
                  visible_to.value === response.data.data.visible_to
              )[0],
            status: statusOptions.filter(
              (status) => status.value === response.data.data.status
            )[0],
          };
          setIsMandatory(response.data.data.is_mandatory);
          reset(editFormData);
          setLoading(false);
        }

        // setLoading(false);
      } catch (err) {
        console.log(err);
        // setError(err.message);
        // setLoading(false);
      }
    };

    fetchEntityData();
  }, [props.entityDocument]);

  return (
    <div>
      {/* Edit Document */}
      <div
        className="modal custom-modal fade modal-padding"
        id="edit_entity_document"
        role="dialog"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">
                Edit Document - {props.entity.entity_name}
              </h5>
              <button
                type="button"
                id="close-edit-modal-entity-document"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="add-details-wizard">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div
                      className="alert alert-secondary alert-dismissible fade show custom-alert-icon shadow-sm d-flex align-items-center"
                      role="alert"
                    >
                      <i className="feather-check-circle flex-shrink-0 me-2" />{" "}
                      Fields marked with{" "}
                      <span className="text-danger"> &nbsp; * &nbsp;</span> are
                      mandatory.
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-info-fieldset">
                {loading && <PreLoader showLoader={loading} />}
                {!loading && (
                  <form
                    onSubmit={handleSubmit(onSubmitEntityDocument)}
                    id="add_document_form"
                  >
                    <div className="contact-input-set">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Document Category{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="document_category"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className={`${
                                    errors?.document_category
                                      ? "error-input"
                                      : ""
                                  }`}
                                  {...field}
                                  onChange={field.onChange}
                                  value={field.value}
                                  options={documentCategoriesOptions}
                                  isClearable={true}
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.document_category?.message}{" "}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label className="col-lg-4 col-form-label">
                            Is Mandatory?
                          </label>
                          <div className="col-lg-8">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="is_mandatory"
                                id="is_mandatory_yes"
                                value="1"
                                checked={isMandatory === 1}
                                onChange={(e) => {
                                  // handleChange(
                                  //   "is_mandatory",
                                  //   e.target.value
                                  // )
                                  setValue("is_mandatory", 1, {
                                    shouldDirty: true,
                                  });
                                  setIsMandatory(1);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="is_mandatory_yes"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="is_mandatory"
                                id="is_mandatory_no"
                                value="0"
                                defaultChecked
                                checked={isMandatory === 0}
                                onChange={(e) => {
                                  // handleChange(
                                  //   "is_mandatory",
                                  //   e.target.value
                                  // );
                                  setValue("is_mandatory", 0, {
                                    shouldDirty: true,
                                  });
                                  setIsMandatory(0);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="is_mandatory_no"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Document Visibility{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="visible_to"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className={`${
                                    errors?.visible_to ? "error-input" : ""
                                  }`}
                                  {...field}
                                  onChange={field.onChange}
                                  value={field.value}
                                  options={visibelToOptions}
                                  isClearable={true}
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.visible_to?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Document Title{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="document_title"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.document_title ? "error-input" : ""
                                  }`}
                                  type="text"
                                  defaultValue=""
                                  maxLength={255}
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.document_title?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-upload-profile">
                            <h6 className="">Document File</h6>
                            <div className="profile-pic-upload">
                              <div className="employee-field">
                                {logoFile ? logoFile.name : "No File Chosen"}
                                <div className="mb-0">
                                  <div className="image-upload mb-0 mx-3">
                                    <input
                                      type="file"
                                      name="entity_document_file"
                                      id="entity_document_file"
                                      onChange={handleFileUpload}
                                    />
                                    <div className="image-uploads">
                                      <h4>Upload</h4>
                                    </div>
                                  </div>
                                </div>
                                <div className="img-reset-btn">
                                  <Link to="#" onClick={resetFileHandler}>
                                    Reset
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <span className="text-danger form-text">
                              {errors.entity_document_file?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Description
                            </label>
                            <Controller
                              name="description"
                              control={control}
                              render={({ field }) => (
                                <textarea
                                  className="form-control"
                                  rows={5}
                                  defaultValue={""}
                                  onChange={field.onChange}
                                  value={field.value}
                                />
                              )}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Status <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="status"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className={`${
                                    errors?.status ? "error-input" : ""
                                  }`}
                                  {...field}
                                  onChange={field.onChange}
                                  value={field.value}
                                  options={statusOptions}
                                  isClearable={true}
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.status?.message}{" "}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-8">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Comments</label>
                            <Controller
                              name="comments"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.comments ? "error-input" : ""
                                  }`}
                                  type="text"
                                  defaultValue=""
                                  maxLength={255}
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.comments?.message}{" "}
                            </span>
                          </div>
                        </div>
                        {/* {showErrors && (
                          <>
                            <div className="col-sm-12">
                              <Alert
                                message={errorsMessage}
                                type="danger"
                                close={true}
                              />
                            </div>
                          </>
                        )} */}
                        <div className="col-lg-12 text-end form-wizard-button">
                          <button
                            className="button btn-lights"
                            type="button"
                            data-bs-dismiss="modal"
                            id="close_update_btn_document"
                          >
                            Close
                          </button>
                          {/* <button
                            className="btn btn-primary wizard-next-btn"
                            type="submit"
                          >
                            Save &amp; Next
                          </button> */}
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={!isDirty}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-primary d-none"
                            type="button"
                            id="success_update_btn_document"
                            data-bs-toggle="modal"
                            data-bs-target="#success_update_msg_document"
                          >
                            Success
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Entity Document */}
      {/* Success Document */}
      <div
        className="modal custom-modal fade"
        id="success_update_msg_document"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon bg-success">
                  <i className="fa fa-check-circle" />
                </div>
                <h3>Entity Document Updated Successfully!!!</h3>
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                    id="close-modal-update-success"
                  >
                    Close
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Success Document */}
    </div>
  );
};

export default EditEntityDocumentModal;
