/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */

import React, { useState } from "react";
import moment from "moment";

const Footer = (props) => {
  return (
    <div className="footer">
      <div className="text-center py-3" style={{ color: "#ccc" }}>
        &copy;{moment().format("YYYY")} Table Space Technologies
      </div>
    </div>
  );
};

export default Footer;
