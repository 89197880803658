import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
const SuccessAlert = ({ itemName, action, onClick }) => {
    // console.log(itemName);
    // const titleText = `${_.capitalize(itemName)} ${(action === 'create' ? 'Created' : 'Updated')} Successfully!!!`    
    const titleText = `${_.capitalize(itemName)} saved successfully!!!`    
    return (
        <>
            <div className="modal-content" id="success_alert_content">
                <div className="modal-body">
                    <div className="success-message text-center">
                        <div className="success-popup-icon bg-success">
                            <i className="fa fa-check-circle" />
                        </div>
                        <h3>{titleText}</h3>
                        <div className="col-lg-12 text-center form-wizard-button">                           
                            <Link
                                to="#"
                                className="button btn-lights"
                                onClick={onClick || null}
                                data-bs-dismiss={onClick === null ? "modal" : null} 
                                id="close-modal-success"
                            >
                                Close
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SuccessAlert;
