import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";
import Footer from "../../layout/Footer";
import { getApiUrl } from "../../../utils/AuthUtils";
import EntityDocuments from "../../../components/Entities/EntityDocuments/EntityDocuments";
import AddEntityDocument from "../../../components/Entities/EntityDocuments/AddEntityDocumentModal";
import Header from "../../../views/layout/Header";

const OrganisationPolicies = () => {
  const branchRef = useRef();
  const documentRef = useRef();
  const navigate = useNavigate();
  const id = 1;
  const [entityData, setEntityData] = useState({});
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const currentUserRole = localStorage.getItem("role");
  const fetchEntityData = async () => {
    try {
      const response = await axios.get(`${getApiUrl()}/api/entities/${id}`);
      setEntityData(response.data.data);
      setLoading(false);
      if (localStorage.getItem("isSubdomain")) {
        localStorage.setItem(
          "entity_logo",
          response.data.data.logo_file.file_path
        );
      }
    } catch (err) {
      // setError(err.message);
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchEntityData();
  }, [localStorage.getItem("isSubdomain"), id]);

  if (loading) {
    return (
      <div className="card-body">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const handleUpdateDocumentsList = () => {
    documentRef.current.updateDocumentsList();
  };

  const handleSearchTextChange = (e) => {
    const value = e.target.value;
    setFilterText(value);
  };
  return (
    <>
      <div className="main-wrapper">
        <Header />
        <div className="page-wrapper m-0">
          {/* Page Content */}
          <div className="content container">
            {/* Page Header */}

            <div className="page-header">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <h3 className="page-title">Company Policies</h3>
                </div>
              </div>
            </div>

            {/* /Page Header */}
            <hr />
            <div className="row">
              {/* Contact Details */}
              <div className="col-xl-12">
                {/* Tab Content */}
                <div className="contact-tab-view">
                  <div className="tab-content pt-0">
                    {/* Documents */}
                    <div>
                      <div className="view-header">
                        <h4>Organization Documents</h4>
                        <ul>
                          <li>
                            <div className="search-set">
                              <div className="search-input">
                                <Link to="#" className="btn btn-searchset">
                                  <i className="las la-search" />
                                </Link>
                                <div className="dataTables_filter">
                                  <label>
                                    {" "}
                                    <input
                                      type="search"
                                      className="form-control form-control-sm"
                                      placeholder="Search"
                                      onChange={handleSearchTextChange}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                          {currentUserRole === "tenant-admin" && (
                            <li>
                              <Link
                                to="#"
                                className="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#add_entity_document"
                              >
                                <i className="la la-plus-circle" /> Add Document
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="contact-activity">
                        <EntityDocuments
                          filterText={filterText}
                          entity={entityData}
                          ref={documentRef}
                        />
                      </div>
                    </div>
                    {/* /Documents */}
                  </div>
                </div>
                {/* /Tab Content */}
              </div>
              {/* /Contact Details */}
            </div>
          </div>
          <Footer />
          <AddEntityDocument
            entity={entityData}
            handleUpdateList={handleUpdateDocumentsList}
          />
        </div>
      </div>
    </>
  );
};

export default OrganisationPolicies;
